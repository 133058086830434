<template>
  <component
    :is="component"
    :disabled="disabled"
    :class="classes"
    :href="href"
    :to="to"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
const style = useCssModule()

const props = defineProps({
  color: {
    type: String,
    default: 'blue',
    validator: (c: string) => ['blue', 'red', 'black'].includes(c)
  },
  variant: {
    type: String,
    default: 'filled',
    validator: (v: string) => ['filled', 'outline'].includes(v)
  },
  size: {
    type: String,
    default: 'large',
    validator: (s: string) => ['large', 'medium'].includes(s)
  },
  disabled: {
    type: Boolean,
    default: false
  },
  to: {
    type: Object,
    default: undefined
  },
  href: {
    type: String,
    default: undefined
  }
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NuxtLink = defineAsyncComponent(() => import('#app/components/nuxt-link'))

// computed
const component = computed(() => {
  if (props.to) { return 'NuxtLink' } else if (props.href) { return 'a' }
  return 'button'
})

const classes = computed(() => {
  return [
    style.PWButton,
    style[props.color],
    style[props.variant],
    style[props.size]
  ]
})
</script>

<style module lang="scss">
  @use '/assets/scss/mixins';

  .PWButton {
    all: unset;
    display: inline-flex;
    padding: var(--py) var(--px);
    border-radius: 4px;
    cursor: pointer;

    @include mixins.button;
  }

  .large {
    --px: 22px;
    --py: 8px;
  }

  .medium {
    --px: 16px;
    --py: 5px;
  }

  .filled {
    transition: background-color 0.3s, color 0.3s;
  }

  .blue.filled {
    --c: var(--white);
    --bg-default: var(--main-blue);
    --bg-hover: var(--main-blue-dark);
    --bg-active: var(--main-blue-light);
  }

  .black.filled {
    --c: var(--white);
    --bg-default: var(--grey-900);
    --bg-hover: var(--grey-800);
    --bg-active: var(--grey-700);
  }

  .filled:not([disabled]) {
    background-color: var(--bg-default);
    color: var(--c);

    &:hover {
      background-color: var(--bg-hover);
    }

    &:active,
    &:focus,
    &:focus-visible {
      background-color: var(--bg-active);
    }
  }

  .filled:disabled {
    background-color: var(--grey-300);
    color: var(--disabled);
    cursor: not-allowed;
  }

  .outline {
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: inset 0 0 0 1px currentColor;
  }

  .blue.outline {
    --c: var(--main-blue);
    --bg-default: var(--white);
    --bg-hover: var(--blue-shade-4);
    --bg-active: var(--blue-shade-8);
  }

  .red.outline {
    --c: var(--main-red);
    --bg-default: var(--white);
    --bg-hover: var(--red-shade-4);
    --bg-active: var(--red-shade-8);
  }

  .outline:not([disabled]) {
    background-color: var(--bg-default);
    color: var(--c);

    &:hover {
      background-color: var(--bg-hover);
    }

    &:active,
    &:focus,
    &:focus-visible {
      background-color: var(--bg-active);
    }
  }

  .outline:disabled {
    background-color: var(--white);
    color: var(--disabled);
    cursor: not-allowed;
  }
</style>
